<template>
	<div class="page">
		<Tables ref="xTable" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.task/add')" addName="新增任务" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按任务名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="task_name" title="任务名称" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.platform]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="task_type_text" title="任务类型" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="condition" title="任务达成条件" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="reset_type_text" title="重置周期" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="展示ICON" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-image style="width: 100px; height: 48px;position: relative;left: -15px" :src="row.icon"
						:preview-src-list="viewImgList(row)" fit="contain">
					</el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="details" title="任务描述" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="tz_type_text" title="跳转方式" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="url" title="跳转页面" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="有效时间" align="center" min-width="160px">
				<template v-slot="{ row }">
					<span>{{ row.start_time }} - {{ row.end_time }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.task/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('market.task/del')">删除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 新增 -->
		<el-dialog title="新增任务" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="650px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="120px" class="form">
				<el-form-item label="任务名称" prop="task_name">
					<el-input v-model="addDialogFormData.task_name" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="addDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="任务描述" prop="details">
					<el-input v-model="addDialogFormData.details" type="textarea" rows='2' clearable
						style="width:80%" />
				</el-form-item>
				<el-form-item label="前端展示ICON" prop="plan_name">
					<upload :fileList="imageList" @getFileInfo="getFileInfo">
					</upload>
					<label style="position: relative;top: -20px;">
						<label>90*90px(宽*高)<span style="color: red">(最大1M)</span></label>
					</label>
				</el-form-item>
				<el-form-item label="任务类型" prop="task_type">
					<el-select v-model="addDialogFormData.task_type" placeholder="请选择任务类型" size="small">
						<el-option label="首次登录APP" :value="1"></el-option>
						<el-option label="绑定车牌" :value="2"></el-option>
						<el-option label="充电度数" :value="3"></el-option>
						<el-option label="连续充电次数" :value="4"></el-option>
						<el-option label="累计充电次数" :value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="重置周期" prop="reset_type">
					<el-select v-model="addDialogFormData.reset_type" placeholder="请选择重置周期" size="small">
						<el-option label="不重置" :value="0"></el-option>
						<el-option label="每天" :value="1"></el-option>
						<el-option label="每周" :value="2"></el-option>
						<el-option label="每月" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="任务达成条件" prop="condition">
					<el-input-number :precision="2" :min="0" v-model="addDialogFormData.condition" clearable
						size="small" />
				</el-form-item>

				<el-form-item label="任务达成奖励" prop="rewards">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onItemAdd(addDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="reward in addDialogFormData.rewards" class="coupon-item">
							<el-select v-model="reward.type" class="select" size="small" style="width:100px" @change="changeItem">
								<el-option label="优惠券" :value="0"></el-option>
								<el-option label="电卡" :value="1"></el-option>
							</el-select>
							<template v-if="reward.type == 1">
								<el-select v-model="reward.card" class="select" size="small" placeholder="请选择电卡" style="width:100px">
									<el-option v-for="item in cardList" :key="item.card_id" :label="item.card_name" :value="item.card_id">
									</el-option>
								</el-select>
							</template>
							<template v-else>
								<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券" style="width:100px">
									<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
									</el-option>
								</el-select>								
							</template>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0" style="width:100px"
								v-model="reward.num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onItemDel(addDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="跳转类型" prop="tz_type">
					<el-radio-group v-model="addDialogFormData.tz_type">
						<el-radio :label="0">不跳转</el-radio>
						<el-radio :label="20">链接</el-radio>
						<el-radio :label="30">程序内地址</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接" prop="content"
					v-if="addDialogFormData.tz_type==20 || addDialogFormData.tz_type==30">
					<el-input v-model="addDialogFormData.url" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="任务时间" prop="rangeTime">
					<el-date-picker v-model="addDialogFormData.rangeTime" @input="dateSet" value-format="yyyy-MM-dd"
						type="daterange" size="small" clearable>
					</el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.task/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑任务" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="650px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
				<el-form-item label="任务名称" prop="task_name">
					<el-input v-model="editDialogFormData.task_name" style="width: 45%;" size="small"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="任务描述" prop="details">
					<el-input v-model="editDialogFormData.details" type="textarea" rows='2' clearable
						style="width:80%" />
				</el-form-item>
				<el-form-item label="前端展示ICON" prop="plan_name">
					<upload :fileList="imageList" @getFileInfo="getFileInfoEdit">
					</upload>
					<label style="position: relative;top: -20px;">
						<label>90*90px(宽*高)<span style="color: red">(最大1M)</span></label>
					</label>
				</el-form-item>
				<el-form-item label="任务类型" prop="task_type">
					<el-select v-model="editDialogFormData.task_type" placeholder="请选择任务类型" size="small">
						<el-option label="首次登录APP" :value="1"></el-option>
						<el-option label="绑定车牌" :value="2"></el-option>
						<el-option label="充电度数" :value="3"></el-option>
						<el-option label="连续充电次数" :value="4"></el-option>
						<el-option label="累计充电次数" :value="5"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="重置周期" prop="reset_type">
					<el-select v-model="editDialogFormData.reset_type" placeholder="请选择重置周期" size="small">
						<el-option label="不重置" :value="0"></el-option>
						<el-option label="每天" :value="1"></el-option>
						<el-option label="每周" :value="2"></el-option>
						<el-option label="每月" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="任务达成条件" prop="condition">
					<el-input-number :precision="2" :min="0" v-model="editDialogFormData.condition" clearable
						size="small" />
				</el-form-item>

				<el-form-item label="任务达成奖励" prop="rewards">
					<div class="coupon-choose-form">
						<div class="title">
							<span class="tips"></span>
							<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
								@click="onItemAdd(editDialogFormData)">增加优惠券</el-button>
						</div>
						<div v-for="(reward,idx) in editDialogFormData.rewards" class="coupon-item" :key="idx">
							<el-select v-model="reward.type" class="select" size="small" style="width:100px" @change="changeItem">
								<el-option label="优惠券" :value="0"></el-option>
								<el-option label="电卡" :value="1"></el-option>
							</el-select>
							<template v-if="reward.type == 1">
								<el-select v-model="reward.card" class="select" size="small" placeholder="请选择电卡" style="width:100px">
									<el-option v-for="item in cardList" :key="item.card_id" :label="item.card_name" :value="item.card_id">
									</el-option>
								</el-select>
							</template>
							<template v-else>
								<el-select v-model="reward.coupon" class="select" size="small" placeholder="请选择优惠券" style="width:100px">
									<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
									</el-option>
								</el-select>
							</template>
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0" style="width:100px" v-model="editDialogFormData.rewards[idx].num" class="input" size="small" placeholder="请输入数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
								@click="onItemDel(editDialogFormData,idx)">删除</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="跳转类型" prop="tz_type">
					<el-radio-group v-model="editDialogFormData.tz_type">
						<el-radio :label="0">不跳转</el-radio>
						<el-radio :label="20">链接</el-radio>
						<el-radio :label="30">程序内地址</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="链接" prop="content"
					v-if="editDialogFormData.tz_type==20 || editDialogFormData.tz_type==30">
					<el-input v-model="editDialogFormData.url" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="任务时间" prop="rangeTime">
					<el-date-picker v-model="editDialogFormData.rangeTime" @input="dateSet" value-format="yyyy-MM-dd"
						type="daterange" size="small" clearable>
					</el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					v-if="$hasAccess('market.task/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'market-task-list',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				isLoading: false,
				tableData: [],
				totalPage: 0,
				imageList: [],
				couponList: [],
				cardList: [], //电卡列表
				searchFormData: {},
				addDialogShow: false, // 新增
				addDialogFormData: {
					rewards: []
				},
				rules: {
					task_name: [{
						required: true,
						message: '请输入任务名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change'
					}],
					task_type: [{
						required: true,
						message: '请选择任务类型',
						trigger: 'change'
					}],
					condition: [{
						required: true,
						message: '请输入任务达成条件',
						trigger: 'change'
					}],
					rangeTime: [{
						required: true,
						message: '请输入有效时间',
						trigger: 'change'
					}],
					reset_type: [{
						required: true,
						message: '请选择重置周期',
						trigger: 'change'
					}],
					tz_type: [{
						required: true,
						message: '请选择跳转方式',
						trigger: 'change'
					}],
				},
				editDialogShow: false, // 编辑
				editDialogFormData: {
					rewards: []
				},
				editNeedData: ['id', 'task_name']
			}
		},
		computed: {
			viewImgList() {
				return function(row) {
					return [row.icon]
				}
			}
		},
		methods: {
			dateSet(v) { //设置日期范围
				this.$forceUpdate();
			},
			getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				let searchParams = {
					_keywords: this.searchFormData.key
				};
				this.isLoading = true
				this.$api.Market.GetTaskList(params, searchParams).then(res => {
					this.totalPage = res.total
					this.tableData = res.data
					for (let d of this.tableData) {
						d.rangeTime = [d.start_time, d.end_time];
						for(let r of d.rewards){
							r.type = r.type||0;
						}
					}
					this.isLoading = false
				}).catch(err => {
					this.isLoading = false
				})
			},
			clearSearch() {
				this.searchFormData = {
					key: '',
				}
			},
			// 重置表单
			resetFormData() {
				this.addDialogFormData = {
					rewards: []
				};
				this.imageList = [];
				this.editDialogFormData = {};
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields();
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields();
				}
				this.getCouponList();
			},
			//添加
			addOne(row) {
				this.resetFormData();
				this.addDialogShow = true;
			},
			//编辑
			editOne(row) {
				this.resetFormData();
				this.editDialogShow = true;
				this.editDialogFormData = Object.assign({}, row);
			},
			//删除
			deleteOne(row) {
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				this.$api.Market.DeleteTask(params).then(() => {
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				});
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate((vali) => {
					if (!vali) return;
					if (!this.addDialogFormData.attach_id) {
						this.$message.warning('请上传ICON')
						return;
					};
					this.addDialogFormData.start_time = this.addDialogFormData.rangeTime[0];
					this.addDialogFormData.end_time = this.addDialogFormData.rangeTime[1];
					const form = {
						task_name: this.addDialogFormData.task_name,
						details: this.addDialogFormData.details,
						task_type: this.addDialogFormData.task_type,
						condition: this.addDialogFormData.condition,
						reset_type: this.addDialogFormData.reset_type,
						tz_type: this.addDialogFormData.tz_type,
						attach_id: this.addDialogFormData.attach_id,
						url: this.addDialogFormData.url,
						start_time: this.addDialogFormData.start_time,
						end_time: this.addDialogFormData.end_time,
						rewards: this.addDialogFormData.rewards,
						platform: this.addDialogFormData.platform,
					}
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(form),
					}
					this.$api.Market.AddTask(params).then(() => {
						this.$notify({
							title: "成功",
							message: "添加成功",
							type: "success",
						});
						this.closeDialog();
						this.$refs.xTable.refreshTable()
					});
				})
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate((vali) => {
					if (!vali) return;
					this.editDialogFormData.start_time = this.editDialogFormData.rangeTime[0];
					this.editDialogFormData.end_time = this.editDialogFormData.rangeTime[1];
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.editDialogFormData)
					}
					this.$api.Market.EditTask(params).then(() => {
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					});
				})
			},
			//附件
			getFileInfo(fileInfo) {
				if (this.addDialogFormData.attach_id && this.addDialogFormData.attach_id == fileInfo && this
					.addDialogFormData.attach_id.indexOf(",") == "-1") {
					//执行删除
					const params = {
						token: this.$store.state.user.token,
						attach_id: this.addDialogFormData.attach_id
					}
					this.$api.Ad.DeleteImg(params);
					this.addDialogFormData.attach_id = "";
					return;
				}
				this.addDialogFormData.attach_id = fileInfo;
			},
			// 附件
			getFileInfoEdit(fileInfo) {
				this.editDialogFormData.attach_id = fileInfo;
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
			// 获取优惠券信息
			async getCouponList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				};
				let res = await this.$api.Market.GetCouponList(params, {});
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;
				//获取个电卡列表
				res = await this.$api.Market.GetCardList(params, {});
				for (let d of res.data) {
					d.card_name = d.card_name + '(' + d.card_money + (d.type==20?'度':'元') + ')';
				}
				this.cardList = res.data;
			},
			//增加
			onItemAdd: function(item) {
				item.rewards.push({
					"type":0,
					"coupon": null,
					"num": 1
				});
			},
			//删除
			onItemDel: function(item, idx) {
				item.rewards.splice(idx, 1)
			},
			changeItem: function(){
				this.$forceUpdate();
			}
		}
	}
</script>
<style>
	.coupon-choose-form {
		.coupon-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 5px;
				width: 80px;
			}

			.input {
				margin-left: 5px;
				width: 80px;
			}

			.del-button {
				margin-left: 10px;
			}
		}
	}
</style>
